<template>
  <div v-if="getRosetteVisibility">
    <div style="margin-left:20px">
      <VueText class="rosettes-title">Rozetlerim</VueText>
      <div style="display: flex; flex-wrap: wrap; ">
        <div v-for="(rosette, index) in getRosettesInfo" :key="index">
          <div class="rosette-box" @click="selectCurrentRosette(rosette)">
            <VueText style="font-weight: 500;color: #23303D;">{{ rosette.rosetteName }}</VueText>
            <div class="circular-progress">
              <div class="progress-bar" :id="`progress-bar-${index}`">
                <img
                  :src="`${rosette.rosetteImageUrl}`"
                  style="position: absolute; height: 40px; width: 40px;"
                />
              </div>
            </div>
            <VueText style="color: #23303D; font-weight: 300;"
              >{{ rosette.rosetteCount }} / {{ rosette.rosetteReferenceCount }}</VueText
            >
          </div>
          <RouterFromRosettes
            style=" margin: 20px 0 0 20px;"
            @openNpsSurveyPopup="openNpsSurveyPopup"
            :hasActiveMarathons="hasActiveMarathons"
            :hasNpsSurvey="hasNpsSurvey"
            :rosette="rosette"
          />
        </div>
        <BrandInfoPopup
          style="backdrop-filter: blur(1px) !important;"
          :show.sync="showRosetteModal"
        >
          <div>
            <RosettePopupContent :selectedRosette="selectedRosette" />
            <RouterFromRosettes
              :rosette="selectedRosette"
              @openNpsSurveyPopup="openNpsSurveyPopup"
              :hasActiveMarathons="hasActiveMarathons"
              :hasNpsSurvey="hasNpsSurvey"
            />
          </div>
        </BrandInfoPopup>
      </div>

      <div v-if="isVisibleSurpriseAwardBox" class="surprise-box" @click="openSupriseModal">
        <div>
          <div class="title">Süpriz Ödülleri Kaçırma</div>
          <div class="content">
            {{ supriseBoxContent }}
          </div>
        </div>
        <img
          src="~@/assets/rosettes/rosette-surprise-key-button.svg"
          alt="rosette key"
          class="box-key"
        />
      </div>
      <BrandInfoPopup
        style="backdrop-filter: blur(1px) !important;"
        :wrapperContentPadding="'0'"
        :show.sync="showSupriseModal"
        @update:show="closeSupriseModal"
      >
        <RosetteRewardPopupContent />
      </BrandInfoPopup>
      <NpsSurvey
        v-if="isNpsRosette"
        :npsSurveyId="npsSurveyId"
        :isRosetteNps="true"
        @closeModal="closeRosetteModal"
      />
    </div>
    <RosettesHistory :triggerRefresh="triggerRefresh" />
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';
import RosettePopupContent from './RosettePopupContent.vue';
import RosetteInfo from '@/mixins/rosetteInfo.js';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import RouterFromRosettes from './RouterFromRosettes.vue';
import GameApi from '@/services/Api/gamification';
import StorageHelper from '@/utils/storageHelper';
import RosettesHistory from './RosettesHistory.vue';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import StaticContent from '@/services/Api/staticContents';
import RosetteRewardPopupContent from './RosetteRewardPopupContent.vue';

export default {
  name: 'RosettesDetail',
  components: {
    VueText,
    RosettePopupContent,
    BrandInfoPopup,
    NpsSurvey,
    RouterFromRosettes,
    RosettesHistory,
    RosetteRewardPopupContent,
  },
  mixins: [RosetteInfo, gtmUtils],
  data() {
    return {
      selectedRosette: {},
      showRosetteModal: false,
      isNpsRosette: false,
      hasActiveMarathons: false,
      hasActiveMarathonsTriggered: false,
      hasNpsSurvey: false,
      npsSurveyId: 0,
      supriseBoxContent: '',
      showSupriseModal: false,
      triggerRefresh: false,
    };
  },
  computed: {
    getRosetteRewardPoint() {
      return this.rosetteRewardPoint;
    },
    getRosetteRewardText() {
      return this.rosetteRewardText;
    },
  },
  methods: {
    openSupriseModal() {
      this.showSupriseModal = !this.showSupriseModal;
    },
    closeSupriseModal() {
      this.getRosetteRewardStatu();
      this.triggerRefresh = !this.triggerRefresh;
    },
    getRosetteSurpriseAwardBox() {
      StaticContent.getStaticContent('RosetteSurpriseAwardBox').then(res => {
        const { content } = res.data.Data;
        this.supriseBoxContent = content;
      });
    },
    selectCurrentRosette(rosette) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('rozet', {
        type: rosette.rosetteName,
      });
      this.selectedRosette = rosette;
      this.showRosetteModal = true;
      this.isNpsRosette = false;
    },
    calculateProgressValue(f) {
      return (100 / f.rosetteReferenceCount) * f.rosetteCount;
    },
    openNpsSurveyPopup() {
      this.isNpsRosette = !this.isNpsRosette;
      this.showRosetteModal = false;
    },
    closeRosetteModal() {
      this.isNpsRosette = false;
      this.getNpsRosetteDetail();
    },
    async activeMarathons() {
      if (!this.hasActiveMarathonsTriggered) {
        const result = await GameApi.checkActiveMarathons();
        result?.data?.Data?.marathon?.forEach(f => {
          if (f.marathonName == 'Haftanın Bulmacası') {
            new StorageHelper({ id: 'PlayableInfo' }).set(f);
            this.hasActiveMarathons = true;
          }
        });
        this.hasActiveMarathonsTriggered = true;
      }
    },
    getNpsRosetteDetail() {
      Common.getNpsRosetteDetail().then(res => {
        if (res?.data?.Data) {
          this.npsSurveyId = res?.data?.Data.surveyId;
          this.hasNpsSurvey = !!res.data.Data.surveyId && res.data.Data.surveyId != 0;
        }
      });
    },
  },
  async updated() {
    await this.setProgressValue();
  },
  async mounted() {
    this.getRosetteSurpriseAwardBox();
    this.getRosetteRewardStatu();
    await this.setProgressValue();
    await this.getRosettesList();
    await this.activeMarathons();
    this.getNpsRosetteDetail();
  },
};
</script>

<style scoped lang="scss">
.rosettes-title {
  font-size: 24px;
  font-weight: 400;
  margin: 50px 0 0 20px;
}
.rosette-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 20px;
  padding: 15px;
  display: flex;
  width: 180px;
  height: 200px;
  cursor: pointer;
  text-align: center;
}
.rosette-box:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.circular-progress {
  display: flex;
  border-radius: 8px;
  background: #fff;
  row-gap: 30px;
  flex-direction: column;
  align-items: center;
}
.progress-bar {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
}
.progress-bar::before {
  content: '';
  position: absolute;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
}
.rosette-modal-content {
  width: 350px;
  height: 400px;
  margin-top: 20px;
}

.meter {
  box-sizing: content-box;
  height: 6px;
  position: relative;
  margin: 10px 0 10px 0;
  background: #d9d9d9;
  border-radius: 30px;
  box-shadow: inset 0 -1px 1px #d9d9d9;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: linear-gradient(315deg, #d20051 0%, #ffa300 100%);
  position: relative;
  overflow: hidden;
}
.progress-bar-modal {
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
}
.progress-bar-modal::before {
  content: '';
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #fff;
}
.enjoy-button {
  margin-top: 15px;
  height: 35px !important;
  background: #ffa300 !important;
}
.surprise-box {
  background-image: url('~@/assets/rosettes/rosette-surprise-box.svg');
  margin: 5% 20px;
  width: 990px;
  height: 115px;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  color: #fff;
  .title {
    color: #fff;
    font-size: 20px;
    margin-top: 30px;
    font-weight: 700;
    margin-left: 70px;
  }
  .content {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-left: 70px;
  }
  .box-key {
    margin: 35px 0 35px 35px;
    width: 170px;
    height: 45px;
  }
}
</style>
